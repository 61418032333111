.search-box {
  border-radius: 14px;
  border: 1px solid #353945;
  background: linear-gradient(
    93deg,
    rgba(35, 38, 47, 0.8) 3.54%,
    rgba(35, 38, 47, 0.6) 93.38%
  );
  box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1);
  backdrop-filter: blur(16px);
}

.search-input {
  color: var(--neutrals-4, #777e90);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
