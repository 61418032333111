.app-container {
  /* background: url("../assets/background.png"), */
    /* lightgray 0px 0px / 100.156% 106.834% no-repeat; */
  width: 1920px;
  height: 1080px;
  background-color: black;
  /* z-index: -5; */
}

.blur-overlay {
  /* background: linear-gradient(
    180deg,
    rgba(35, 38, 47, 0.56) 30.57%,
    rgba(35, 38, 47, 0.8) 81.59%
  );
  backdrop-filter: blur(2px); */
}

.left-circle {
  position: absolute;
  width: 800px;
  height: 800px;
  flex-shrink: 0;
  border-radius: 800px;
  border: 140px solid rgba(59, 193, 204, 0.5);
  backdrop-filter: blur(10px);
  bottom: -400px;
  left: -400px;
  /* z-index: -2; */
}

.right-circle {
  position: absolute;
  width: 800px;
  height: 800px;
  flex-shrink: 0;
  border-radius: 800px;
  border: 140px solid rgba(194, 213, 0, 0.25);
  backdrop-filter: blur(10px);
  top: -400px;
  right: -400px;
  /* z-index: -2; */
}
