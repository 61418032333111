.tenant-card {
  /* border-radius: 0px;
  border: 1px solid #353945;
  background: linear-gradient(
    93deg,
    rgba(35, 38, 47, 0.8) 3.54%,
    rgba(35, 38, 47, 0.6) 93.38%
  );
  box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1);
  backdrop-filter: blur(16px); */
}

.carousel-slider {
  height: 100%;
}

.slider-wrapper, .slider-wrapper > ul {
  height: 100%;
}

.no-data-label {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

